/* Header.css */
.header {
    display: flex;
    align-items: flex-start; 
    padding: 20px;
    flex-direction: column;
    align-items: center;
}

.profile-pic {
    max-width: 25%;
    height: auto;
    margin-bottom: 20px;
}

.header-text {
    text-align: center;
}

.header-text > :first-child {
    margin-top: 0;
}

.header p {
    margin: 0.5em 0;
}

@media (min-width: 768px) {
    .header {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    .header-text {
        margin-left: 20px;
        text-align: left;
    }

    .profile-pic {
        margin-right: 20px;
    }
}
