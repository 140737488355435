/* Tile.css */

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }



.tile {
    flex-basis: calc(20% - 10px); /* Adjust the "20px" to change the margin */
    margin: 10px; /* Half of the margin subtracted from flex-basis */
    width: 200px;
    height: 200px;
    border: 1px solid #333;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: #f0f0f0; /* Change the text color to light */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    /* glassmorphism */
    background: rgba( 0, 0, 0, 0.25 ); /* Change the background to darker */
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    transition: transform 0.3s ease-in-out;
    animation: fadeIn 1s;
    padding-top: 50px;  /* Adjust as necessary */

    position: relative; /* Make sure to include this line as z-index doesn't work on static elements. */
    z-index: 1; /* Set a z-index lower than the dialog's z-index. */
}

.tile a {
    color: #ffffff;
}

.tile a:hover {
    color: lightblue;
}


.tile:hover {
    background-color: #333;
    transform: scale(1.1);
    position: relative;
    z-index: 10;
}

.tile-title {
    margin: 0;
    padding: 0;
    text-align: center;
}

.tile-description {
    margin: 0;
    padding: 0;
    text-align: left;
}
