/* Dialog.css */
.dialog {
    position: absolute;
    top: 50%; /* Changed from 100px to 50% */
    left: 50%; /* Changed from 100px to 50% */
    background: rgba(0, 0, 0, 0.9);
    color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    overflow-y: auto;
    transform: translate(-50%, -40%);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    pointer-events: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 10;
    text-align: left;
}

/* Styles for bold text */
.dialog strong, .dialog b {
    color: #ffffff; /* Brighten the color */
    text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff, 0 0 40px #ffffff; /* Add a glowing effect */
}


.tile:hover .dialog {
    opacity: 1;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    z-index: 10;
}

