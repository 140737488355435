/* App.css */

/* Define the scrollbar width and height */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Define the scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #333; /* Dark background color */
}

/* Define the scrollbar thumb (foreground) */
::-webkit-scrollbar-thumb {
  background: #888; /* Lighter color */
  border-radius: 5px; /* Slightly round the corners */
}

/* Add a hover effect on the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: #222;
  color: #f0f0f0;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  margin: 0 auto;
  max-width: 75%;
  border-width: 0;
}

.App h1, .App h2, .App h3, .App h4, .App h5, .App h6 {
  font-weight: bold;
}

.navbar {
  margin-bottom: 2rem;
}


.tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr)); /* Adjusted the min size to be 20% of the screen width */
  gap: 20px;
  justify-items: center;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .tiles-container {
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr)); /* 100%/3 tiles = 33.33% per tile */
  }
}

@media (max-width: 768px) {
  .tiles-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); /* 100%/2 tiles = 50% per tile */
  }
}